import { useEffect } from 'react';

export function useMessageEventListener<T = any>(event: string, callback: (data: T) => void) {
  const handleMessage = (message: MessageEvent) => {
    if (!/^(https?:\/\/)?(localhost(:\d+)?|(saludtools\.(dev|qa)?\.carecloud\.com\.co))$/.test(message.origin)) return;
    if (message.data.type !== event) return;
    callback(message.data.data);
  };

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  });
}
